import { Component, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { Title } from '@angular/platform-browser';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { DownloadService } from '../../../core/services/download.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-client-portal-consult-note-viewer',
  templateUrl: './client-portal-consult-note-viewer.component.html',
  styleUrls: ['./client-portal-consult-note-viewer.component.scss'],
})
export class ClientPortalConsultNoteViewerComponent implements OnInit {
  pdfFileType = PdfFileType.patientAttachment;
  patientAttachment: PatientAttachment;
  refreshing = true;
  initialTitle = null;

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private downloadService: DownloadService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.initialTitle = this.titleService.getTitle();
    const patientAttachmentId = this.navigationService.currentId;
    this.loadPatientAttachment(patientAttachmentId);
  }

  private loadPatientAttachment(patientAttachmentId): void {
    this.refreshing = true;
    this.patientAttachmentsService.get(patientAttachmentId).subscribe(
      patientAttachment => {
        this.patientAttachment = patientAttachment;
        this.refreshing = false;
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );
  }

  onDownloadClick($event) {
    $event.preventDefault();
    this.patientAttachmentsService.get(this.patientAttachment.id).subscribe(attachment => {
      this.downloadService.getDownloadUrl(attachment.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url, null, '_blank');
      });
    });
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.patientAttachment.fileName}`;
    this.titleService.setTitle(title);
  }
}
