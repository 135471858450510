import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExpirableDownload } from '../models/expirable-download.model';

@Injectable()
export class DownloadService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  getDownloadUrl(path: string): Observable<ExpirableDownload> {
    const url = `${environment.captureApi.url}/${path}`;
    return this.http.get<ExpirableDownload>(url);
  }

  download(url: string, filename: string = null, target: string = null) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    if (filename) {
      link.setAttribute('download', filename);
    }
    if (target) {
      link.setAttribute('target', target);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
