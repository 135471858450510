import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PagedPatientAttachments, PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { DownloadService } from 'app/core/services/download.service';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { ClientConsultNoteFilters, ClientConsultNoteListSettings } from '../../../core/models/user-settings/client-consult-note-settings.model';
import { ClientsService } from '../../../core/services/clients.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { debounce } from 'lodash-es';

@Component({
  selector: 'app-directly-sent-consult-notes',
  templateUrl: './directly-sent-consult-notes.component.html',
})
export class DirectlySentConsultNotesComponent implements OnInit {
  patientAttachments: PatientAttachment[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  loading = true;

  filters: ClientConsultNoteFilters = new ClientConsultNoteFilters();
  canFilterByClients = false;
  clients;

  debounceFilterChange = debounce(
    () => {
      this.onFilterChange();
    },
    1000,
    { maxWait: 2000 }
  );

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private downloadService: DownloadService,
    private userSettingsService: UserSettingsService,
    private clientsService: ClientsService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canFilterByClients = this.authService.isCaptureAdminUser && !this.authService.viewingAsClient;
    this.loadQueueSettings();
    this.loadClients();
    this.loadPatientAttachments();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadPatientAttachments();
  }

  onPageChange(page) {
    this.paging.currentPage = page;
    this.loadPatientAttachments();
  }

  onFilterChange() {
    this.saveQueueSettings();
    this.loadPatientAttachments();
  }

  onTextFilterChange() {
    this.debounceFilterChange();
  }

  onClearClick() {
    this.filters = new ClientConsultNoteFilters();
    this.saveQueueSettings();
    this.loadPatientAttachments();
  }

  private loadClients() {
    if (this.canFilterByClients) {
      this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
        this.clients = result.clients;
      });
    }
  }

  private loadPatientAttachments() {
    this.loading = true;

    const { currentPage, pageSize } = this.paging;

    this.patientAttachmentsService
      .getDirectMessageList(this.filters, currentPage, pageSize)
      .subscribe((pagedPatientAttachments: PagedPatientAttachments) => {
        this.paging = pagedPatientAttachments.meta.paging;
        this.patientAttachments = pagedPatientAttachments.patientAttachments || [];
        this.loading = false;
      });
  }

  download($event, patientAttachment: PatientAttachment) {
    $event.preventDefault();
    this.patientAttachmentsService.get(patientAttachment.id).subscribe(attachment => {
      this.downloadService.getDownloadUrl(attachment.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  get showTable(): boolean {
    return !this.loading && this.patientAttachments && this.patientAttachments.length > 0;
  }

  get noResults(): boolean {
    return !this.loading && this.patientAttachments.length === 0;
  }

  private loadQueueSettings() {
    const queueSettings: ClientConsultNoteListSettings = this.userSettingsService.get<ClientConsultNoteListSettings>(
      UserSettings.clientPortalConsultNoteListSettings
    );
    if (queueSettings) {
      this.filters = queueSettings.filters;
      this.paging = queueSettings.paging || LimitOffsetPaging.empty;

      // if we are viewing a client as a capture admin
      // and the capture admin has selectedClientIds set from
      // the admin view, remove those selected client ids.
      if (!this.canFilterByClients) {
        this.filters.selectedClientIds = [];
      }
    }
  }

  private saveQueueSettings() {
    const queueSettings = new ClientConsultNoteListSettings();
    queueSettings.filters = this.filters;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<ClientConsultNoteListSettings>(
      UserSettings.clientPortalConsultNoteListSettings, queueSettings
    );
  }
}
