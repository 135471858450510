import { mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { CaptureSnapshotsService } from '../../../core/services/capture-snapshots.service';
import { Capture } from '../../../core/models/capture.model';
import { CaptureSnapshot } from '../../../core/models/capture-snapshot.model';
import { DownloadService } from 'app/core/services/download.service';

const snapshotPollingInterval = 3000;

@Component({
  selector: 'app-capture-snapshot-activity',
  templateUrl: './capture-snapshot-activity.component.html',
  styleUrls: ['./capture-snapshot-activity.component.scss'],
})
export class CaptureSnapshotActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() capture: Capture;
  @Input() captureSnapshot: CaptureSnapshot;

  @Output() public snapshotActivityComplete = new EventEmitter<CaptureSnapshot>();

  snapshotPollingSubscription = null;

  constructor(
    private captureSnapshotsService: CaptureSnapshotsService,
    private downloadService: DownloadService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  ngOnDestroy() {
    this.stopSnapshotPolling();
  }

  get canDownload() {
    return this.captureSnapshot.fileExpirableDownloadPath;
  }

  onDownloadClick() {
    this.downloadService.getDownloadUrl(this.captureSnapshot.fileExpirableDownloadPath).subscribe(expirableDownload => {
      this.downloadService.download(expirableDownload.url);
    });
  }

  private init() {
    this.stopSnapshotPolling();

    if (this.captureSnapshot.processing) {
      this.startSnapshotPolling(this.captureSnapshot.id);
    }
  }

  private startSnapshotPolling(captureSnapshotId: number) {
    this.snapshotPollingSubscription = interval(snapshotPollingInterval)
      .pipe(mergeMap(() => this.captureSnapshotsService.get(captureSnapshotId)))
      .subscribe(captureSnapshot => {
        if (!captureSnapshot.processing) {
          this.stopSnapshotPolling();
          this.snapshotActivityComplete.emit(captureSnapshot);
        }
      });
  }

  private stopSnapshotPolling() {
    if (this.snapshotPollingSubscription) {
      this.snapshotPollingSubscription.unsubscribe();
    }
  }
}
