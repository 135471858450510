<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />

  <div class="page-container">
    <div class="section mt-3">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-10">
              <h4>Notes</h4>
            </div>
          </div>

          <div class="section mt-2">
            <app-notes-for-model [model]="patient" [modelType]="'Patient'" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"><app-patient-demographics [patient]="patient" /></div>
    <div class="mt-3"><app-patient-grouped-patients [patient]="patient" /></div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>
