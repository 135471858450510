import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientInteractionLocationImport } from 'app/core/models/patient-interaction-location-import.model';
import { PatientInteractionLocationImportsService } from 'app/core/services/patient-interaction-location-imports.service';
import { DownloadService } from 'app/core/services/download.service';
import { ExpirableDownload } from 'app/core/models/expirable-download.model';

@Component({
  selector: 'app-capture-admin-import-patient-interaction-locations-history',
  templateUrl: './capture-admin-import-patient-interaction-locations-history.component.html',
})
export class CaptureAdminImportPatientInteractionLocationsHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  patientInteractionLocationImports: PatientInteractionLocationImport[] = [];
  nextCheckTimeout = null;

  constructor(
    private patientInteractionLocationImportsService: PatientInteractionLocationImportsService,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.loadImports(true);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onCancelClick($event, patientInteractionLocationImport: PatientInteractionLocationImport) {
    $event.preventDefault();
    this.patientInteractionLocationImportsService.
      cancel(patientInteractionLocationImport.id).subscribe(invoiceImport => {
        const index = this.patientInteractionLocationImports.findIndex(imp => imp.id === invoiceImport.id);

        if (index > -1) {
          this.patientInteractionLocationImports[index] = invoiceImport;
          this.patientInteractionLocationImports = [...this.patientInteractionLocationImports];
        }
      });
  }

  onDownloadOriginalClick($event, patientInteractionLocationImport: PatientInteractionLocationImport) {
    $event.preventDefault();
    this.patientInteractionLocationImportsService.get(patientInteractionLocationImport.id).subscribe(invoiceImport => {
      const downloadUrl = invoiceImport.fileExpirableDownloadPath;
      this.downloadService.getDownloadUrl(downloadUrl).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onDownloadSkippedClick($event, patientInteractionLocationImport: PatientInteractionLocationImport) {
    $event.preventDefault();
    this.patientInteractionLocationImportsService.get(patientInteractionLocationImport.id).subscribe(invoiceImport => {
      const downloadUrl = invoiceImport.skippedFileExpirableDownloadPath;
      this.downloadService.getDownloadUrl(downloadUrl).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  private loadImports(loading) {
    this.loading = loading;
    this.patientInteractionLocationImportsService.getList().subscribe(data => {
      this.patientInteractionLocationImports = data.patientInteractionLocationImports;
      this.loading = false;

      const anyActive: boolean = this.patientInteractionLocationImports.some(invoiceImport => invoiceImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(false), 500);
      }
    });
  }
}
