import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

const DefaultConfig = { ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href', 'target', 'rel'] };
const TempDataAttribute = 'data-temp-href-target';

function isElement(node: Node): node is Element {
  return node.nodeType === node.ELEMENT_NODE;
}

// https://github.com/cure53/DOMPurify/issues/317#issuecomment-912474068
DOMPurify.addHook('beforeSanitizeAttributes', node => {
  if (isElement(node)) {
    if (node.tagName === 'A') {
      if (!node.hasAttribute('target')) {
        node.setAttribute('target', '_self');
      }

      if (node.hasAttribute('target')) {
        node.setAttribute(TempDataAttribute, node.getAttribute('target'));
      }
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', node => {
  if (isElement(node)) {
    if (node.tagName === 'A' && node.hasAttribute(TempDataAttribute)) {
      node.setAttribute('target', node.getAttribute(TempDataAttribute));
      node.removeAttribute(TempDataAttribute);

      if (node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener noreferrer');
      }
    }
  }
});

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any, config = DefaultConfig): SafeHtml {
    const sanitizedContent = DOMPurify.sanitize(value, config);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
