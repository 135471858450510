import { Component, OnInit, OnDestroy } from '@angular/core';
import { forEach } from 'lodash-es';
import { InboundFax } from 'app/core/models/inbound-fax.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { DownloadService } from 'app/core/services/download.service';
import { InboundFaxesService, PagedInboundFaxes } from 'app/core/services/inbound-faxes.service';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { ClientsService } from '../../../core/services/clients.service';
import { FaxFilters, FaxQueueSettings } from '../../../core/models/user-settings/fax-settings.model';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { UsersService } from 'app/core/services/users.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { statusOptions } from 'app/core/options/inbound-fax-status.opts';

@Component({
  templateUrl: './capture-admin-inbound-faxes.component.html',
  styleUrls: ['./capture-admin-inbound-faxes.component.scss']
})
export class CaptureAdminInboundFaxesComponent implements OnInit, OnDestroy {
  inboundFaxes: InboundFax[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  filters: FaxFilters = new FaxFilters();
  loading = true;

  faxStatuses = statusOptions;
  clients = [];
  assignedToUsers = [];

  constructor(
    private inboundFaxesService: InboundFaxesService,
    private clientsService: ClientsService,
    private downloadService: DownloadService,
    private userSettingsService: UserSettingsService,
    private userTimingService: UserTimingService,
    private usersService: UsersService,
    private userPresenceService: UserPresenceService
  ) {}

  ngOnInit() {
    this.init();
    this.startTimeTracking();
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
    this.userPresenceService.unsubscribeAll();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.getFaxes(this.paging.currentPage);
    this.saveQueueSettings();
  }

  onPageChange(page) {
    this.getFaxes(page);
    this.saveQueueSettings();
  }

  onClearClick($event) {
    $event.preventDefault();
    this.filters = new FaxFilters();
    this.saveQueueSettings();
    this.getFaxes(1);
  }

  onFilterChange() {
    this.saveQueueSettings();
    this.getFaxes(1);
    this.startTimeTracking();
  }

  download($event, inboundFax: InboundFax) {
    $event.preventDefault();
    this.downloadService.getDownloadUrl(inboundFax.fileExpirableDownloadPath).subscribe(expirableDownload => {
      this.downloadService.download(expirableDownload.url, null, '_blank');
    });
  }

  private init() {
    this.loadQueueSettings();
    this.loadClients();
    this.loadAssignedUsers();
    this.getFaxes(1);
  }

  getFaxes(page) {
    this.loading = true;
    this.userPresenceService.unsubscribeAll();
    this.inboundFaxesService
      .getList(
        page,
        this.paging.pageSize,
        this.filters.selectedStatuses,
        this.filters.selectedClientIds,
        this.filters.selectedAssignedToIds,
        this.filters.includeArchived
      )
      .subscribe((result: PagedInboundFaxes) => {
        this.inboundFaxes = result.inboundFaxes;
        this.paging = result.meta.paging;
        this.loading = false;
        this.subscribeToUserPresence();
      });
  }

  onViewClick(position: number) {
    this.saveQueueSettings(position);
  }

  private loadQueueSettings() {
    const queueSettings: FaxQueueSettings = this.userSettingsService.get<FaxQueueSettings>(
      UserSettings.captureAdminFaxQueueSettings
    );

    if (queueSettings) {
      this.filters = queueSettings.filters;
      this.paging = queueSettings.paging;
    } else {
      this.filters.selectedStatuses = [InboundFaxStatus.fresh];
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new FaxQueueSettings();
    queueSettings.position = position;
    queueSettings.filters = this.filters;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<FaxQueueSettings>(UserSettings.captureAdminFaxQueueSettings, queueSettings);
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadAssignedUsers() {
    this.usersService.getByUserRole([UserRoleName.faxQueue]).subscribe((result) => {
      this.assignedToUsers = result.users;
    });
  }

  private startTimeTracking() {
    const data: any = { page: 'inbound_fax_queue' };
    data.statuses = this.filters.selectedStatuses;

    this.userTimingService.track(data);
  }

  private subscribeToUserPresence() {
    forEach(this.inboundFaxes, (inboundFax: InboundFax) => {
      this.userPresenceService.subscribe("fax", inboundFax.id)
    })
  }
}
