<div class="my-2">
  <ng-container *ngIf="!loading; else: spinner">
    <div [class.compact]="compactClientView">
      <table
        [formGroup]="formGroup"
        class="table table-sm"
        *ngIf="verificationsOrOptions.length > 0 || showAddForm; else: noReferrals">

        <thead [ngClass]="{ 'table-dark': dark }">
          <tr>
            <th>Referral Date</th>
            <th>
              <ng-container *ngIf="compactClientView">
                Referral Type
              </ng-container>

              <ng-container *ngIf="!compactClientView">
                Evidence Type
              </ng-container>
            </th>
            <th>Referral Notes</th>
            <th>Prescriber</th>
            <th>Specialty</th>
            <th *ngIf="!compactClientView">Date Found</th>
            <th *ngIf="!compactClientView">Found By</th>
            <th *ngIf="!compactClientView">Note To Client</th>
            <th *ngIf="!compactClientView">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            formArrayName="options"
            *ngFor="let item of verificationsOrOptions; let i = index">

            <ng-container>
              <tr [class.selected]="option(item)?.selected">
                <td>
                  <label *ngIf="option(item)">
                    <input type="checkbox" class="form-check-input" [formControlName]="i" />
                    {{ verification(item).referralDate | date: 'MM/dd/yyyy' }}
                  </label>

                  <div *ngIf="!option(item)">
                    {{ verification(item).referralDate | date: 'MM/dd/yyyy' }}
                  </div>
                </td>
                <td>
                  {{ verification(item).reasons | referralVerificationReasonShortNames | joinList }}
                </td>
                <td>
                  {{ verification(item).otherReason | blankValueDash }}
                </td>
                <td>
                  <div>{{ verification(item).prescriber.fullName }}</div>
                  <div>NPI: {{ verification(item).prescriber.npi }}</div>
                  <div>{{ verification(item).prescriber.displaySpecialty }}</div>
                </td>
                <td>
                  {{ verification(item).referralSpecialty | blankValueDash }}
                </td>
                <td *ngIf="!compactClientView">
                  {{ verification(item).createdAt | date: 'MM/dd/yyyy' }}
                </td>
                <td *ngIf="!compactClientView">
                  {{ verification(item).user?.fullName | blankValueDash }}
                </td>
                <td *ngIf="!compactClientView">
                  {{ verification(item).notes | blankValueDash }}
                </td>
                <td *ngIf="!compactClientView">
                  <a href="javascript:;"
                    (click)="editingId = verification(item).id"
                    [class.invisible]="optionEditable(option(item))">
                    Edit
                  </a>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="editingId === verification(item).id">
              <tr class="edit-row">
                <td colspan="99" class="px-2">
                  <app-ehr-referral-verification-form
                    [patientPrescriber]="patientPrescriber"
                    [prescriber]="prescriber"
                    [ehrReferralVerification]="verification(item)"
                    (canceled)="onEditCanceled()"
                    (saved)="onEditSave($event)" />
                </td>
              </tr>
            </ng-container>
          </ng-container>

          <tr *ngIf="showAddForm">
            <td colspan="99">
              <app-ehr-referral-verification-form
                [patientPrescriber]="patientPrescriber"
                [prescriber]="prescriber"
                (canceled)="onAddCanceled()"
                (saved)="onAddSave($event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <button
    class="btn btn-sm btn-secondary mt-1"
    (click)="onAddClick()"
    *ngIf="editable && !showAddForm && !editingId">
    Add Referral Evidence
  </button>

</div>

<ng-template #spinner>
  <div class="mt-1 mb-2 ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noReferrals>
  <div class="my-2">Patient has no applicable referral evidence.</div>
</ng-template>
