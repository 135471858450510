import { EventEmitter, Output, Directive, OnInit } from '@angular/core';
import { debounce } from 'lodash-es';
import { ClientsService } from '../../../core/services/clients.service';
import { CaptureQueueService } from '../../../core/services/capture-queue.service';
import { UserAssignedClientsService } from 'app/core/services/user-assigned-clients.service';
import { CaptureStatus } from '../../../core/enums/capture-status.enum';
import {
  CaptureQueueFilters,
  CaptureQueueSettings,
} from '../../../core/models/user-settings/capture-queue-settings.model';
import { CaptureQueueCursorType } from '../../../core/enums/capture-queue-cursor-type.enum';
import { flatten, isEmpty, uniq } from 'lodash-es';
import { reasonsForStatus, transitionOptions } from '../../../core/options/capture-status.opts';
import { ClaimSourceOptions } from '../../../core/options/claim-source.opts';
import { displayCaptureStatus } from '../../../core/options/capture-status.opts';

const CursorTypeOptions = [
  { value: CaptureQueueCursorType.highestEstimatedValue, display: 'Estimated Value' },
  { value: CaptureQueueCursorType.highestPendingEstimatedValue, display: 'Pending Estimated Value' },
  { value: CaptureQueueCursorType.highestWipEstimatedValue, display: 'WIP Estimated Value' },
  { value: CaptureQueueCursorType.mostRecentlyFaxed, display: 'Manual Fax Date: Newest' },
  { value: CaptureQueueCursorType.leastRecentlyFaxed, display: 'Manual Fax Date: Oldest' },
  { value: CaptureQueueCursorType.mostRecentlyCreated, display: 'Created On' },
  { value: CaptureQueueCursorType.mostRecentlyVerified, display: 'Verified On' },
];

@Directive()
export abstract class BaseCaptureQueueSettingsComponent implements OnInit {
  filters: CaptureQueueFilters = new CaptureQueueFilters();
  cursorType: CaptureQueueCursorType = CaptureQueueCursorType.highestEstimatedValue;

  statusFilterOptions = [];
  statusReasonFilterOptions = [];
  clientFilterOptions = [];
  cursorTypeOptions = CursorTypeOptions;
  claimSourceOptions = ClaimSourceOptions;
  assignedUsersFilterOptions = [];

  debouncedHandleSettingsChange = debounce(
    () => {
      this.handleSettingsChange();
    },
    250,
    { maxWait: 1000 }
  );

  ignoreStatusReasonChange = false;

  @Output() public settingsChange = new EventEmitter();

  constructor(
    private clientsService: ClientsService,
    protected captureQueueService: CaptureQueueService,
    private userAssignedClientsService: UserAssignedClientsService
  ) {}

  ngOnInit() {
    this.initialize();
  }

  onStatusFilterChange() {
    this.initStatusReasonFilterOptions();
    this.fireSettingsChange();
  }

  onStatusReasonFilterChange() {
    if (!this.ignoreStatusReasonChange) {
      this.fireSettingsChange();
    }
  }

  onFilterChange() {
    this.fireSettingsChange();
  }

  onCursorTypeChange() {
    this.fireSettingsChange();
  }

  onResetClick($event) {
    this.handleResetClick($event);
  }

  protected initialize() {
    this.initStatusFilterOptions();
    this.initClientFilterOptions();
    this.initAssignedUsersFilterOptions();
    this.loadUserSettings();
    this.initStatusReasonFilterOptions();
    this.saveUserSettings();
  }

  protected handleSettingsChange() {
    this.saveUserSettings();
    this.settingsChange.emit();
  }

  protected handleResetClick($event) {
    $event.preventDefault();
    this.filters = new CaptureQueueFilters();
    this.initStatusReasonFilterOptions();
    this.fireSettingsChange();
  }

  protected get currentUserSettings(): CaptureQueueSettings {
    const filters = Object.assign({}, this.filters);
    return new CaptureQueueSettings(filters, this.cursorType);
  }

  private initStatusFilterOptions() {
    this.statusFilterOptions = Object.values(CaptureStatus)
      .filter(v => v !== CaptureStatus.discarded)
      .map(v => ({
        value: v,
        display: displayCaptureStatus(v),
      }));
  }

  private initStatusReasonFilterOptions() {
    this.ignoreStatusReasonChange = true;
    let statusReasonFilterOptions;

    if (isEmpty(this.filters.statuses)) {
      statusReasonFilterOptions = transitionOptions.map(status => status.reasonOptions);
    } else {
      statusReasonFilterOptions = this.filters.statuses.map(status => reasonsForStatus(status));
    }

    this.statusReasonFilterOptions = uniq(flatten(statusReasonFilterOptions));

    this.filters.reasons = this.filters.reasons.filter(selected =>
      this.statusReasonFilterOptions.find(r => r.value === selected)
    );
    this.ignoreStatusReasonChange = false;
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  private initAssignedUsersFilterOptions() {
    this.userAssignedClientsService.getList().subscribe(result => {
      this.assignedUsersFilterOptions = result.users;
    });
  }

  private fireSettingsChange() {
    this.debouncedHandleSettingsChange();
  }

  private loadUserSettings() {
    const queueSettings = this.captureQueueService.getUserSettings();
    this.filters = queueSettings.filters;
    this.cursorType = queueSettings.cursorType;
  }

  private saveUserSettings() {
    this.captureQueueService.saveUserSettings(this.currentUserSettings);
  }
}
