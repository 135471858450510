import { Component, ViewChild, Output, Input, OnInit, OnChanges, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import PSPDFKit, { Annotation, ToolbarItem } from "pspdfkit";
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { PdfFile } from '../../../core/interfaces/pdf-file';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { UploadService } from '../../../core/services/upload.service';
import { DownloadService } from '../../../core/services/download.service';
import { FileTargetsService } from '../../../core/services/file-targets.service';
import { environment } from '../../../../environments/environment';
import { Mutex } from 'async-mutex';
import { PdfFileService } from 'app/core/services/pdf-file.service';

const viewOnlyToolbarItems: ToolbarItem[] = [
  { type: "sidebar-annotations", title: "Show Annotations",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M13.75 8.95q-.3 0-.525-.225Q13 8.5 13 8.2q0-.325.225-.538.225-.212.525-.212h7q.325 0 .538.212.212.213.212.538 0 .3-.212.525-.213.225-.538.225Zm0 7.6q-.3 0-.525-.213Q13 16.125 13 15.8q0-.3.225-.525.225-.225.525-.225h7q.325 0 .538.225.212.225.212.525 0 .325-.212.537-.213.213-.538.213Zm-8.7-6.35L3.025 8.15q-.225-.2-.213-.513.013-.312.213-.512.2-.225.525-.225t.525.225l1.6 1.6L9.4 5q.225-.225.525-.225.3 0 .525.25.2.225.2.525 0 .3-.2.525L6.325 10.2q-.275.275-.637.275-.363 0-.638-.275Zm0 7.625-2.025-2.05q-.225-.2-.213-.513.013-.312.213-.537.2-.2.525-.2t.525.2l1.6 1.625L9.4 12.625q.225-.225.525-.225.3 0 .525.225.2.225.2.525 0 .3-.2.525l-4.125 4.15q-.275.275-.637.275-.363 0-.638-.275Z"/></svg>' },
  { type: "sidebar-thumbnails", title: "Show Document Thumbnails",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 11q-.625 0-1.062-.438Q3.5 10.125 3.5 9.5V5q0-.625.438-1.062Q4.375 3.5 5 3.5h4.5q.625 0 1.062.438Q11 4.375 11 5v4.5q0 .625-.438 1.062Q10.125 11 9.5 11Zm0 9.5q-.625 0-1.062-.438Q3.5 19.625 3.5 19v-4.5q0-.625.438-1.062Q4.375 13 5 13h4.5q.625 0 1.062.438.438.437.438 1.062V19q0 .625-.438 1.062-.437.438-1.062.438Zm9.5-9.5q-.625 0-1.062-.438Q13 10.125 13 9.5V5q0-.625.438-1.062.437-.438 1.062-.438H19q.625 0 1.062.438.438.437.438 1.062v4.5q0 .625-.438 1.062Q19.625 11 19 11Zm0 9.5q-.625 0-1.062-.438Q13 19.625 13 19v-4.5q0-.625.438-1.062Q13.875 13 14.5 13H19q.625 0 1.062.438.438.437.438 1.062V19q0 .625-.438 1.062-.437.438-1.062.438ZM5 9.5h4.5V5H5Zm9.5 0H19V5h-4.5Zm0 9.5H19v-4.5h-4.5ZM5 19h4.5v-4.5H5Zm9.5-9.5Zm0 5Zm-5 0Zm0-5Z"/></svg>' },
  // Removed sidebar-document-outline, sidebar-bookmarks
  { type: "pager" },
  // Removed pan
  { type: "zoom-out", title: "Zoom Out",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M19.025 20.05 13.25 14.3q-.75.625-1.725.975-.975.35-2 .35-2.575 0-4.35-1.775Q3.4 12.075 3.4 9.5q0-2.55 1.775-4.338 1.775-1.787 4.35-1.787 2.55 0 4.325 1.775 1.775 1.775 1.775 4.35 0 1.075-.35 2.05-.35.975-.95 1.7l5.775 5.775q.2.2.188.513-.013.312-.213.512-.225.225-.537.225-.313 0-.513-.225Zm-9.5-5.925q1.925 0 3.263-1.35 1.337-1.35 1.337-3.275 0-1.925-1.337-3.275-1.338-1.35-3.263-1.35-1.95 0-3.287 1.35Q4.9 7.575 4.9 9.5q0 1.925 1.338 3.275 1.337 1.35 3.287 1.35ZM7.85 10.25q-.3 0-.512-.213-.213-.212-.213-.537 0-.325.225-.538.225-.212.525-.212h3.3q.325 0 .525.212.2.213.2.538 0 .325-.212.537-.213.213-.538.213Z"/></svg>' },
  { type: "zoom-in", title: "Zoom In",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M19.025 20.05 13.25 14.3q-.75.625-1.725.975-.975.35-2 .35-2.575 0-4.35-1.775Q3.4 12.075 3.4 9.5q0-2.55 1.775-4.338 1.775-1.787 4.35-1.787 2.55 0 4.325 1.775 1.775 1.775 1.775 4.35 0 1.075-.35 2.05-.35.975-.95 1.7l5.775 5.775q.2.2.188.513-.013.312-.213.512-.225.225-.537.225-.313 0-.513-.225Zm-9.5-5.925q1.925 0 3.263-1.35 1.337-1.35 1.337-3.275 0-1.925-1.337-3.275-1.338-1.35-3.263-1.35-1.95 0-3.287 1.35Q4.9 7.575 4.9 9.5q0 1.925 1.338 3.275 1.337 1.35 3.287 1.35Zm0-1.925q-.325 0-.537-.225-.213-.225-.213-.525v-1.2H7.55q-.3 0-.513-.213-.212-.212-.212-.537 0-.325.212-.538.213-.212.538-.212h1.2V7.525q0-.3.213-.513.212-.212.537-.212.325 0 .537.225.213.225.213.525v1.2h1.2q.325 0 .525.212.2.213.2.538 0 .325-.212.537-.213.213-.538.213h-1.175v1.225q0 .3-.213.512-.212.213-.537.213Z"/></svg>' },
  { type: "zoom-mode", title: "Zoom Mode",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M4.3 19.5q-.75 0-1.275-.525Q2.5 18.45 2.5 17.7V6.3q0-.75.525-1.275Q3.55 4.5 4.3 4.5h15.4q.75 0 1.275.525.525.525.525 1.275v11.4q0 .75-.525 1.275-.525.525-1.275.525Zm0-1.5h.9V6h-.9q-.125 0-.212.088Q4 6.175 4 6.3v11.4q0 .125.088.213.087.087.212.087Zm2.4 0h10.6V6H6.7Zm12.1 0h.9q.125 0 .213-.087.087-.088.087-.213V6.3q0-.125-.087-.212Q19.825 6 19.7 6h-.9ZM6.7 6v12Z"/></svg>'},
  { type: "spacer" }
]

const editToolbarItems: ToolbarItem[] = [
  // Removed annotate, ink, text-highlighter, signature, image, stamp
  { type: "note", title: "Note Annotation",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M9.55 17.275q-.175 0-.337-.063-.163-.062-.288-.187L4.75 12.85q-.225-.225-.213-.538.013-.312.213-.537.225-.225.538-.225.312 0 .512.225l3.75 3.75 8.65-8.65q.2-.225.525-.225t.525.225q.225.225.225.537 0 .313-.225.538l-9.075 9.075q-.125.125-.287.187-.163.063-.338.063Z"/></svg>' },
  // Removed highlighter, ink-eraser, text, line, arrow, rectangle
  // Removed ellipse, polygon, cloudy-polygon, polyline,
  { type: "document-editor", title: "Document Editor",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M18 22.85q-1.4 0-2.55-.737-1.15-.738-1.75-1.913-.125-.2-.037-.45.087-.25.312-.35.225-.075.45 0t.35.3q.45.875 1.3 1.412.85.538 1.925.538 1.525 0 2.588-1.063Q21.65 19.525 21.65 18q0-1.525-1.062-2.587Q19.525 14.35 18 14.35q-.825 0-1.55.35-.725.35-1.275.95h1.075q.25 0 .425.175t.175.425q0 .25-.175.425t-.425.175h-2.2q-.375 0-.637-.263-.263-.262-.263-.637v-2.2q0-.25.175-.425t.425-.175q.25 0 .425.175t.175.425v1.05q.7-.75 1.638-1.2.937-.45 2.012-.45 2 0 3.425 1.425Q22.85 16 22.85 18q0 2-1.425 3.425Q20 22.85 18 22.85ZM5 19V5v14ZM16 8.875q.325 0 .538-.225.212-.225.212-.525 0-.325-.212-.537-.213-.213-.538-.213H8q-.325 0-.537.213-.213.212-.213.537 0 .3.213.525.212.225.537.225Zm-2 2.375H8q-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213h4.2q.375-.45.825-.825.45-.375.975-.675Zm-3.3 3.875H8q-.325 0-.537.225-.213.225-.213.525 0 .325.213.538.212.212.537.212h2.275q.05-.4.163-.762.112-.363.262-.738ZM5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v5.275q-.35-.125-.725-.225T19 10.225V5.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2v13.4q0 .1.1.2t.2.1h4.925q.025.4.125.775t.225.725Z"/></svg>' },
  // Removed print, document-crop, search, export-pdf
  // " }
  { type: "custom", id: "keyboard-shortcuts",
    title: "Press Ctl/Cmd I to start a note\nPress Ctl/Cmd B for the document editor",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M10 15q.417 0 .708-.292Q11 14.417 11 14t-.292-.708Q10.417 13 10 13t-.708.292Q9 13.583 9 14t.292.708Q9.583 15 10 15Zm-.75-3.188h1.521q0-.77.135-1.093.136-.323.656-.823.73-.708 1.011-1.208.281-.5.281-1.105 0-1.145-.781-1.864Q11.292 5 10.083 5q-1.062 0-1.843.562-.782.563-1.094 1.521l1.354.563q.188-.584.594-.906.406-.323.948-.323.583 0 .958.333t.375.875q0 .479-.323.854t-.719.729q-.729.667-.906 1.094-.177.427-.177 1.51ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"/></svg>',
    onPress: () => alert("Keyboard Shortcuts:\nPress Ctl/Cmd I to start a note\nPress Ctl/Cmd B to edit the document"),
  },
  { type: "debug" },
]

@Component({
  selector: 'app-pdfkit-file-viewer',
  templateUrl: './pdfkit-file-viewer.component.html',
  styleUrls: ['./pdfkit-file-viewer.component.scss'],
})
export class PdfkitFileViewerComponent implements OnInit, OnChanges {
  @Input() pdfFile: PdfFile;
  @Input() pdfFileType: PdfFileType;
  @Input() height: string;
  @Output() urlChange = new EventEmitter<string>();

  @ViewChild('viewer') viewer: ElementRef;

  @Output() coreControlsEvent:EventEmitter<string> = new EventEmitter();

  constructor(
    private uploadService: UploadService,
    private downloadService: DownloadService,
    private fileTargetsService: FileTargetsService,
    private authService: AuthenticationService,
    private hotkeysService: HotkeysService,
    private pdfFileService: PdfFileService
  ) {
    this.documentLoaded$ = new Subject<void>();
  }

  private documentLoaded$: Subject<void>;
  userName: string;
  canAnnotate: boolean;
  instance: any;
  instantJSON: any;
  initialViewState: any;
  pdfMutex = new Mutex();
  isLoaded = false;
  hasLoadedChangeHandler = false;
  customToolbarItems: any[];
  licenseKey = environment.psPdfKit?.licenseKey;
  currentUrl: string;

  loadPdfKit = async () => {
    this.isLoaded = false;
    this.instantJSON = null;
    this.currentUrl = this.pdfFile.url;
    if (this.pdfFile.annotations) {
      if (this.pdfFile.annotations.pdfId) delete this.pdfFile.annotations.pdfId;
      this.instantJSON = this.pdfFile.annotations
    }

    if (this.instance) {
      PSPDFKit.unload(this.instance);
      this.instance = null;
    }

    this.instance = await PSPDFKit.load({
      baseUrl: location.protocol + "//" + location.host + "/pdfkit/",
      styleSheets: [
        "/assets/styles/pspdfkit-file-viewer.css"
      ],
      container: ".pspdfkit-container",
      document: this.pdfFile.url,
      toolbarItems: this.customToolbarItems.concat([this.downloadButton]),
      instantJSON: this.instantJSON,
      annotationPresets: { "note": { "icon": "CHECK" } },
      annotationToolbarItems: () => [{ type: "spacer" }, { type: "delete" }],
      isEditableAnnotation: (annotation) => (this.canAnnotate && (annotation.creatorName === this.userName || annotation.creatorName === "System")),
      isEditableComment: (annotation) => (this.canAnnotate && annotation.creatorName === this.userName),
      dateTimeString: ({ dateTime, element }) => new Intl.DateTimeFormat("en-US", {
          dateStyle: "short",
          timeStyle: "short",
        }).format(dateTime),
      licenseKey: this.licenseKey,
      useIframe: true,
      autoSaveMode: PSPDFKit.AutoSaveMode.INTELLIGENT
    });

    this.instance.setViewState(viewState => (viewState.set("sidebarWidth", 225)));
    this.setViewState();
    this.isLoaded = true;
    this.instance.setAnnotationCreatorName(this.userName);

    this.instance.addEventListener("document.change", async () => {
      await this.setInstantJSON();
      this.exportAndSaveFile();
    });

    this.instance.addEventListener("annotations.willSave", async () => {
      this.instantJSON = await this.instance.exportInstantJSON();
      this.saveAnnotation();
    });

    this.instance.contentDocument.addEventListener("keydown", this.keyDownHandler, true);

    if (!this.hasLoadedChangeHandler) {
      this.pdfFileService.annotationsWillChange.subscribe(() => {
        this.reloadPdfAndAnnotations();
      });
      this.hasLoadedChangeHandler = true;
    }
  }

  keyDownHandler = (event) => {
    // Ctrl/⌘ + B to set document editor and Ctrl/⌘ + i to set note annotation
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 73) {
      this.instance.setAnnotationPresets(annotationPresets => ({
        note: {
          ...annotationPresets.note,
        }
      }));
      this.instance.setCurrentAnnotationPreset("note");
      this.instance.setViewState(viewState =>
        viewState.set("interactionMode", PSPDFKit.InteractionMode.NOTE),
      );
    } else if ((event.ctrlKey || event.metaKey) && event.keyCode === 66) {
      this.instance.setViewState(viewState => viewState.set("interactionMode", PSPDFKit.InteractionMode.DOCUMENT_EDITOR));
    }
  }

  downloadButton = {
    type: "custom",
    id: "download-pdf",
    title: "Download",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 15.25q-.175 0-.337-.062-.163-.063-.288-.213l-3.125-3.1q-.2-.2-.2-.525t.2-.525q.225-.225.537-.238.313-.012.538.213l1.925 1.925v-7.65q0-.325.213-.538.212-.212.537-.212.325 0 .538.212.212.213.212.538v7.65l1.925-1.925q.2-.2.525-.2t.55.225q.2.2.2.512 0 .313-.2.538l-3.125 3.1q-.125.15-.287.213-.163.062-.338.062ZM6.3 19.5q-.75 0-1.275-.525Q4.5 18.45 4.5 17.7v-1.95q0-.325.213-.538Q4.925 15 5.25 15q.325 0 .537.212.213.213.213.538v1.95q0 .1.1.2t.2.1h11.4q.1 0 .2-.1t.1-.2v-1.95q0-.325.212-.538.213-.212.538-.212.325 0 .538.212.212.213.212.538v1.95q0 .75-.525 1.275-.525.525-1.275.525Z"/></svg>',
    onPress: () => {
      this.downloadService.getDownloadUrl(this.pdfFile.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url, null, '_blank');
      });
    }
  };

  setViewState = () => {
    if (this.instantJSON?.annotations?.length) {
      this.instance.setViewState(viewState => (viewState.set("sidebarMode", PSPDFKit.SidebarMode.ANNOTATIONS)));
    } else {
      this.instance.setViewState(viewState => (viewState.set("sidebarMode", null)));
    }
  }

  setInstantJSON = async () => {
    const docAnnotations = [];
    for (let index = 0; index < this.instance.totalPageCount; index++) {
      const pageAnnotations = await this.pageAnnotations(index);
      docAnnotations.push(...pageAnnotations);
    }
    this.instantJSON = { annotations: docAnnotations, format: 'https://pspdfkit.com/instant-json/v1' };
  }

  pageAnnotations = async (index) => {
    const annotations = await this.instance.getAnnotations(index);
    return annotations.map((annotation: Annotation) => PSPDFKit.Annotations.toSerializableObject(annotation)).toJSON();
  }

  saveAnnotation = () => {
    this.pdfFile.annotations = this.instantJSON;
    this.fileTargetsService.annotate(this.pdfFile, this.pdfFileType, this.pdfFile.id.toString()).subscribe(
      (result) => {
        this.instantJSON = result.annotations;
        this.setViewState();
      }, (error) => {
        console.log(error);
      }
    );
  }

  exportAndSaveFile = async () => {
    this.instance.exportPDF({excludeAnnotations: true}).then((buffer) => {
      const myFile = new File([buffer], 'file.pdf', { type: "application/pdf" });
      this.saveFile(myFile);
    });
  }

  saveFile = (file: File) => {
    this.uploadService.upload(file).subscribe(
      (response) => {
        this.pdfFile.annotations = this.instantJSON;
        this.fileTargetsService.associate(this.pdfFile, this.pdfFileType, response.signedId).subscribe(
          (pdfFile: PdfFile) => {
            this.pdfFile = pdfFile;
            this.onPdfUrlChange(this.pdfFile.url)
            this.pdfMutex.runExclusive(async () => await this.loadPdfKit());
            this.pdfFileService.notifyPdfFileChanged(pdfFile, this.pdfFileType);
          }, (error) => {
            console.log(error);
          }
        );
      }, (error) => {
        console.log(error);
      }
    );
  }

  ngOnInit() {
    this.userName = this.authService.currentUser?.fullName || '';
    this.canAnnotate = this.authService.isCaptureAdminUser;
    if (this.canAnnotate) {
      this.customToolbarItems = viewOnlyToolbarItems.concat(editToolbarItems);
    } else {
      this.customToolbarItems = viewOnlyToolbarItems;
    }

    // add a ctrl-f handler so that it works on at least some parts of the page
    // (if pspdfkit has focus it will still take over the event)
    this.hotkeysService.add(new Hotkey('mod+f', (event: KeyboardEvent): boolean => {
      event.stopImmediatePropagation();
      return true;
    }));

    this.pdfMutex.runExclusive(async () => await this.loadPdfKit());
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'pdfFile' && this.isLoaded) {
        if (this.pdfFile.url !== this.currentUrl) {
          this.pdfMutex.runExclusive(async () => await this.loadPdfKit());
        }
      }
    });
  }

  getDocumentLoadedObservable() {
    return this.documentLoaded$.asObservable();
  }

  onPdfUrlChange(url: string) {
    this.urlChange.emit(url);
  }

  private reloadPdfAndAnnotations() {
    this.pdfFileService.get(this.pdfFile, this.pdfFileType).subscribe(
      (result) => {
        this.pdfFile.annotations = result.annotations;
        this.pdfFile.annotatedDrugKeys = result.annotatedDrugKeys;
        this.pdfFile.url = result.url;
        if (this.isLoaded) {
          this.onPdfUrlChange(result.url);
          this.pdfMutex.runExclusive(async () => await this.loadPdfKit());
        }
      }, (error) => {
        console.log(error);
      }
    );
  }
}
