import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { PdfFile } from '../interfaces/pdf-file';
import { PdfFileType } from '../enums/pdf-file-type.enum';
import { Target } from '../models/target.model';

@Injectable()
export class FileTargetsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  associate(pdfFile: PdfFile, pdfFileType: PdfFileType, signedId: string): Observable<Target> {
    return this.http.patch<Target>(`${environment.captureApi.url}/file_targets/${signedId}/associate`, {
      file_target: {
        targetId: pdfFile.id,
        targetType: pdfFileType,
        annotationData: JSON.stringify(this.annotations(pdfFile)),
        annotatedDrugKeys: pdfFile.annotatedDrugKeys
      }
    });
  }

  annotate(pdfFile: PdfFile, pdfFileType: PdfFileType, signedId: string): Observable<Target> {
    return this.http.patch<Target>(`${environment.captureApi.url}/file_targets/${signedId}/annotate`, {
      file_target: {
        targetId: pdfFile.id,
        targetType: pdfFileType,
        annotationData: JSON.stringify(this.annotations(pdfFile)),
        annotatedDrugKeys: pdfFile.annotatedDrugKeys
      }
    });
  }

  private annotations(pdfFile: PdfFile) {
    if (pdfFile.annotations) {
      return { annotations: pdfFile.annotations?.annotations,
               format: pdfFile.annotations?.format };
    }
  }
}
