import { Component, EventEmitter, Input, Output, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { EhrReferralMatchOption } from 'app/core/models/ehr-referral-match-option.model';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { SimpleChange } from '@angular/core';
import { handleSimpleChanges } from 'app/core/lib/component-utils';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { Prescriber } from 'app/core/models/prescriber.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { EhrReferralVerificationUpdate } from "app/core/services/ehr-referral-verifications.service";

type ListingIterable = EhrReferralMatchOption | EhrReferralVerification;

@Component({
  selector: 'app-referrals-ehr-listing',
  templateUrl: './referrals-ehr-listing.component.html',
  styleUrls: ['./referrals-ehr-listing.component.scss'],
})
export class ReferralsEhrListingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading = false;
  @Input() editable: boolean;
  @Input() dark = false;

  // PatientPrescriber does not currently have a full Prescriber record, only a prescriber ID.
  // A prescriber record is required to determine recommended specialty in new EHR verification form.
  // Only required when list is ediable
  @Input() prescriber: Prescriber;

  // Only required when list is ediable
  @Input() patientPrescriber: PatientPrescriber;

  @Input() referralMatchOptions: EhrReferralMatchOption[];
  @Input() ehrReferralVerifications: EhrReferralVerification[];

  @Input() compactClientView = false;

  @Output() referralVerificationChange = new EventEmitter<EhrReferralMatchOption>();
  @Output() newEhrReferralVerification = new EventEmitter<EhrReferralVerificationUpdate>();
  @Output() ehrReferralVerificationEdited = new EventEmitter<EhrReferralVerificationUpdate>();

  formGroup: FormGroup;
  showAddForm = false;
  editingId: number;
  valueChangeSubscriptions: Subscription[];

  constructor(
    protected formBuilder: FormBuilder,
    protected authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    if (!this.authService.isCaptureAdminUser || this.compactClientView || !this.referralMatchOptions) {
      this.editable = false;
    }

    this.setupForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string, inputChanges: SimpleChange) => {
      if (inputName === 'referralMatchOptions') {
        if (inputChanges.previousValue && inputChanges.previousValue.length === inputChanges.currentValue.length) {
          this.updateForm();
        } else {
          this.setupForm();
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeFromFormChanges();
  }

  get verificationsOrOptions(): ListingIterable[] {
    return this.referralMatchOptions || this.ehrReferralVerifications;
  }

  verification(item: ListingIterable): EhrReferralVerification {
    if (item instanceof EhrReferralMatchOption) {
      return item.ehrReferralVerification;
    } else {
      return item as EhrReferralVerification;
    }
  }

  option(item: ListingIterable): EhrReferralMatchOption {
    if (item instanceof EhrReferralMatchOption) {
      return item;
    }
  }

  onReferralVerificationChange(referralMatchOption: EhrReferralMatchOption) {
    this.referralVerificationChange.emit(referralMatchOption);
  }

  onAddSave(verificationEvent: EhrReferralVerificationUpdate) {
    this.showAddForm = false;

    this.newEhrReferralVerification.emit(verificationEvent);
  }

  onEditCanceled() {
    delete this.editingId;
  }

  onEditSave(verificationEvent: EhrReferralVerificationUpdate) {
    delete this.editingId;

    this.ehrReferralVerificationEdited.emit(verificationEvent);
  }

  onAddClick() {
    this.showAddForm = true;
  }

  onAddCanceled() {
    this.showAddForm = false;
  }

  optionEditable(option: EhrReferralMatchOption) {
    return !this.editable ||
      option.disabled ||
      option.ehrReferralVerification.frozen ||
      this.editingId ||
      this.showAddForm;
  }

  protected unsubscribeFromFormChanges() {
    if (this.valueChangeSubscriptions) {
      this.valueChangeSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  protected setupForm() {
    this.unsubscribeFromFormChanges();

    const controls =
      (this.referralMatchOptions || []).
        map(option => this.formBuilder.control({ value: option.selected, disabled: option.disabled }));

    this.valueChangeSubscriptions = [];
    controls.forEach((control, index) => {
      // NOTE: subscribing to the whole form only returns values for controls that are not disabled
      // in an array with indexing based only on those controls, not the original form array indexes
      this.valueChangeSubscriptions.push(control.valueChanges.subscribe(value => {
        const option = this.referralMatchOptions[index];
        option.selected = value;
        this.referralVerificationChange.emit(option);
      }));
    });

    this.formGroup = this.formBuilder.group({
      options: this.formBuilder.array(controls)
    });
  }

  protected updateForm() {
    this.referralMatchOptions.forEach((option, index) => {
      const optionsArray = this.formGroup.controls.options as FormArray;
      const control = optionsArray.controls[index];
      const updateOptions = { emitEvent: false };

      control.setValue(option.selected, updateOptions);
      if (option.disabled) {
        control.disable(updateOptions);
      } else {
        control.enable(updateOptions);
      }
    });
  }
}
