import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InboundFaxHeaderComponent } from './components/inbound-fax-header/inbound-fax-header.component';
import { InboundFaxInfoComponent } from './components/inbound-fax-info/inbound-fax-info.component';
import { InboundFaxContentComponent } from './components/inbound-fax-content/inbound-fax-content.component';
import { InboundFaxSelectPatientComponent } from './components/inbound-fax-select-patient/inbound-fax-select-patient.component';
import { PatientSearchFormComponent } from './components/patient-search-form/patient-search-form.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { EncounterListComponent } from './components/encounter-list/encounter-list.component';
import { SelectPrescriberComponent } from './components/select-prescriber/select-prescriber.component';
import { PrescriberListComponent } from './components/prescriber-list/prescriber-list.component';
import { InboundFaxSelectOutcomeComponent } from './components/inbound-fax-select-outcome/inbound-fax-select-outcome.component';
import { CaptureAdminComponent } from './capture-admin.component';
import { CaptureAdminInboundFaxesComponent } from './pages/capture-admin-inbound-faxes/capture-admin-inbound-faxes.component';
import { CaptureAdminInboundFaxComponent } from './pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';
import { CaptureAdminFaxUploadComponent } from './pages/capture-admin-fax-upload/capture-admin-fax-upload.component';
import { CaptureAdminCaptureReportsComponent } from './pages/capture-admin-capture-reports/capture-admin-capture-reports.component';
import { CaptureAdminCaptureBillingStateReportsComponent } from './pages/capture-admin-capture-billing-state-reports/capture-admin-capture-billing-state-reports.component';
import { CaptureAdminCaptureInvoiceLineItemReportsComponent } from './pages/capture-admin-capture-invoice-line-item-reports/capture-admin-capture-invoice-line-item-reports.component';
import { CaptureAdminSpecialtyStoreClaimPatientMatchReportsComponent } from './pages/capture-admin-specialty-store-claim-patient-match-reports/capture-admin-specialty-store-claim-patient-match-reports.component';
import { CaptureAdminUserTimingReportsComponent } from './pages/capture-admin-user-timing-reports/capture-admin-user-timing-reports.component';
import { CaptureAdminMissingNdcReportsComponent } from './pages/capture-admin-missing-ndc-reports/capture-admin-missing-ndc-reports.component';
import { CaptureAdminFaxablePatientPrescriberReportsComponent } from './pages/capture-admin-faxable-patient-prescriber-reports/capture-admin-faxable-patient-prescriber-reports.component';
import { CaptureAdminClientsComponent } from './pages/capture-admin-clients/capture-admin-clients.component';
import { ClientListComponent } from './components/client-list/client-list.component';
import { CaptureAdminClientComponent } from './pages/capture-admin-client/capture-admin-client.component';
import { CaptureAdminAuditComponent } from './pages/capture-admin-audit/capture-admin-audit.component';
import { CaptureAdminClientOptionsAuditComponent } from './pages/capture-admin-client-options-audit/capture-admin-client-options-audit.component';
import { CaptureAdminClientAuditComponent } from './pages/capture-admin-client-audit/capture-admin-client-audit.component';
import { CaptureAdminClientConsultNotesComponent } from './pages/capture-admin-client-consult-notes/capture-admin-client-consult-notes.component';
import { CaptureAdminClientPrescriberTasksComponent } from './pages/capture-admin-client-prescriber-tasks/capture-admin-client-prescriber-tasks.component';
import { ClientPrescriberTaskStatusComponent } from './components/client-prescriber-task-status/client-prescriber-task-status.component';
import { ClientPrescriberTaskListComponent } from './components/client-prescriber-task-list/client-prescriber-task-list.component';
import { ClientPrescriberTaskCapturesComponent } from './components/client-prescriber-task-captures/client-prescriber-task-captures.component';
import { ClientPrescriberTaskVisibilityComponent } from './components/client-prescriber-task-visibility/client-prescriber-task-visibility.component';
import { InboundFaxCapturesComponent } from './components/inbound-fax-captures/inbound-fax-captures.component';
import { InboundFaxProcessFormComponent } from './components/inbound-fax-process-form/inbound-fax-process-form.component';
import { InboundFaxViewProcssedComponent } from './components/inbound-fax-view-processed/inbound-fax-view-processed.component';
import { CaptureAdminFileCheckResultsComponent } from './pages/capture-admin-file-check-results/capture-admin-file-check-results.component';
import { CaptureAdminFileCheckComponent } from './pages/capture-admin-file-check/capture-admin-file-check.component';
import { CaptureAdminClientFilesComponent } from './pages/capture-admin-client-files/capture-admin-client-files.component';
import { CaptureAdminPartnerFilesComponent } from './pages/capture-admin-partner-files/capture-admin-partner-files.component';
import { FileCheckNavComponent } from './components/file-check-nav/file-check-nav.component';
import { FileCheckStatusComponent } from './components/file-check-status/file-check-status.component';
import { CaptureAdminUsersComponent } from './pages/capture-admin-users/capture-admin-users.component';
import { CaptureAdminUserComponent } from './pages/capture-admin-user/capture-admin-user.component';
import { CaptureAdminUserNewComponent } from './pages/capture-admin-user-new/capture-admin-user-new.component';
import { UserActionsComponent } from './components/user-actions/user-actions.component';
import { CaptureAdminPartnerPortalClaimReportComponent } from './pages/capture-admin-partner-portal-claim-report/capture-admin-partner-portal-claim-report.component';
import { CaptureAdminUserEditComponent } from './pages/capture-admin-user-edit/capture-admin-user-edit.component';
import { UserFiltersComponent } from './components/user-filters/user-filters.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { CaptureAdminImportReferralPrescriberSpecialtyMappingComponent } from './pages/capture-admin-import-referral-prescriber-specialty-mapping/capture-admin-import-referral-prescriber-specialty-mapping.component';
import { CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent } from './pages/capture-admin-import-referral-prescriber-specialty-mappings-history/capture-admin-import-referral-prescriber-specialty-mappings-history.component';
import { CaptureAdminImportOutboundFaxesComponent } from './pages/capture-admin-import-outbound-faxes/capture-admin-import-outbound-faxes.component';
import { CaptureAdminImportOutboundFaxesHistoryComponent } from './pages/capture-admin-import-outbound-faxes-history/capture-admin-import-outbound-faxes-history.component';
import { CaptureAdminImportClaimsComponent } from './pages/capture-admin-import-claims/capture-admin-import-claims.component';
import { CaptureAdminImportClaimsHistoryComponent } from './pages/capture-admin-import-claims-history/capture-admin-import-claims-history.component';
import { CaptureAdminClientEditComponent } from './pages/capture-admin-client-edit/capture-admin-client-edit.component';
import { CaptureAdminClientEditConfigurationComponent } from './pages/capture-admin-client-edit-configuration/capture-admin-client-edit-configuration.component';
import { CaptureAdminClientNewComponent } from './pages/capture-admin-client-new/capture-admin-client-new.component';
import { CaptureAdminClientConsultNoteTasksComponent } from './pages/capture-admin-client-consult-note-tasks/capture-admin-client-consult-note-tasks.component';
import { ClientConsultNoteTaskListComponent } from './components/client-consult-note-task-list/client-consult-note-task-list.component';
import { OutboundFaxesComponent } from './components/outbound-faxes/outbound-faxes.component';
import { CaptureAdminImportCaptureInvoiceLineItemsComponent } from './pages/capture-admin-import-capture-invoice-line-items/capture-admin-import-capture-invoice-line-items.component';
import { CaptureAdminImportCaptureInvoiceLineItemsHistoryComponent } from './pages/capture-admin-import-capture-invoice-line-items-history/capture-admin-import-capture-invoice-line-items-history.component';
import { CaptureAdminImportPatientInteractionLocationsComponent } from './pages/capture-admin-import-patient-interaction-locations/capture-admin-import-patient-interaction-locations.component';
import { CaptureAdminImportPatientInteractionLocationsHistoryComponent } from './pages/capture-admin-import-patient-interaction-locations-history/capture-admin-import-patient-interaction-locations-history.component';
import { ClientActionsComponent } from './components/client-actions/client-actions.component';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { CaptureAdminCaptureQueueComponent } from './pages/capture-admin-capture-queue/capture-admin-capture-queue.component';
import { CaptureQueueSettingsComponent } from './components/capture-queue-settings/capture-queue-settings.component';
import { CaptureQueueResultsComponent } from './components/capture-queue-results/capture-queue-results.component';
import { SendDirectTestMessageComponent } from './components/send-direct-test-message/send-direct-test-message.component';
import { CaptureAdminClientDirectConsultNotesComponent } from './pages/capture-admin-client-direct-consult-notes/capture-admin-client-direct-consult-notes.component';
import { CaptureAdminClientReferralTasksComponent } from './pages/capture-admin-client-referral-tasks/capture-admin-client-referral-tasks.component';
import { ClientReferralTaskListComponent } from './components/client-referral-task-list/client-referral-task-list.component';
import { CaptureAdminInboundCallsComponent } from './pages/capture-admin-inbound-calls/capture-admin-inbound-calls.component';
import { CaptureAdminInboundCallComponent } from './pages/capture-admin-inbound-call/capture-admin-inbound-call.component';
import { CaptureAdminInboundCallNewComponent } from './pages/capture-admin-inbound-call-new/capture-admin-inbound-call-new.component';
import { OutboundFaxSearchFormComponent } from './components/outbound-fax-search-form/outbound-fax-search-form.component';
import { OutboundFaxSelectorComponent } from './components/outbound-fax-selector/outbound-fax-selector.component';
import { CaptureAdminPrescriberIncorrectInformationRequestsComponent } from './pages/capture-admin-prescriber-incorrect-information-requests/capture-admin-prescriber-incorrect-information-requests.component';
import { CaptureAdminNameMappingsComponent } from './pages/capture-admin-name-mappings/capture-admin-name-mappings.component';
import { CaptureAdminClientChoresComponent } from './pages/capture-admin-client-chores/capture-admin-client-chores.component';
import { CaptureAdminClientChoreCreateComponent } from './pages/capture-admin-client-chore-create/capture-admin-client-chore-create.component';
import { CaptureAdminImportCuratedSpecialtiesComponent } from './pages/capture-admin-import-curated-specialties/capture-admin-import-curated-specialties.component';
import { CaptureAdminImportCuratedSpecialtiesHistoryComponent } from './pages/capture-admin-import-curated-specialties-history/capture-admin-import-curated-specialties-history.component';
import { CapturesModule } from '../captures/captures.module';
import { ConsultNotesModule } from '../consult-notes/consult-notes.module';
import { ClientTasksModule } from '../client-tasks/client-tasks.module';
import { IccModule } from '../icc/icc.module';
import { CaptureAdminCaptureSearchComponent } from './pages/capture-admin-capture-search/capture-admin-capture-search.component';
import { CaptureSearchSettingsComponent } from './components/capture-search-settings/capture-search-settings.component';
import { CaptureSearchResultsComponent } from './components/capture-search-results/capture-search-results.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CaptureAdminPatientsComponent } from './pages/capture-admin-patients/capture-admin-patients.component';
import { CaptureAdminPatientComponent } from './pages/capture-admin-patient/capture-admin-patient.component';
import { CaptureAdminProvidersComponent } from './pages/capture-admin-providers/capture-admin-providers.component';
import { CaptureAdminProviderComponent } from './pages/capture-admin-provider/capture-admin-provider.component';
import { CaptureAdminProviderNewComponent } from './pages/capture-admin-provider-new/capture-admin-provider-new.component';
import { CaptureAdminProvidersMechanicalTurkQueueComponent } from './pages/capture-admin-providers-mechanical-turk-queue/capture-admin-providers-mechanical-turk-queue.component';
import { CaptureAdminProviderOfficeStatusReviewQueueComponent } from './pages/capture-admin-provider-office-status-review-queue/capture-admin-provider-office-status-review-queue.component';
import { CaptureAdminOfficeStatusReviewQueueComponent } from './pages/capture-admin-office-status-review-queue/capture-admin-office-status-review-queue.component';
import { ProviderOfficeListComponent } from './components/provider-office-list/provider-office-list.component';
import { ProviderOfficeListBasicComponent } from './components/provider-office-list-basic/provider-office-list-basic.component';
import { ProviderOfficeNewFormComponent } from './components/provider-office-new-form/provider-office-new-form.component';
import { ProviderOfficeNewRowComponent } from './components/provider-office-new-row/provider-office-new-row.component';
import { ProviderNavComponent } from './components/provider-nav/provider-nav.component';
import { ProviderHeaderComponent } from './components/provider-header/provider-header.component';
import { ProviderHeaderEditComponent } from './components/provider-header-edit/provider-header-edit.component';
import { ProviderInformationCollectionDetailsComponent } from './components/provider-information-collection-details/provider-information-collection-details.component';
import { ProviderDetailComponent } from './components/provider-detail/provider-detail.component';
import { ProviderCallLogFormComponent } from './components/provider-call-log-form/provider-call-log-form.component';
import { ProviderCallLogsComponent } from './components/provider-call-logs/provider-call-logs.component';
import { ProviderCommunicationsComponent } from './components/provider-communications/provider-communications.component';
import { ProviderOutboundFaxesComponent } from './components/provider-outbound-faxes/provider-outbound-faxes.component';
import { PrescriptionsComponent } from './components/prescriptions/prescriptions.component';
import { PatientFiltersComponent } from './components/patient-filters/patient-filters.component';
import { ClientConfigurationBasicValueComponent } from './components/client-configuration-basic-value/client-configuration-basic-value.component';
import { ClientConfigurationDatedValueComponent } from './components/client-configuration-dated-value/client-configuration-dated-value.component';
import { ClientConfigurationSuboptionComponent } from './components/client-configuration-suboption/client-configuration-suboption.component';
import { ClientConfigurationSuboptionsComponent } from './components/client-configuration-suboptions/client-configuration-suboptions.component';
import { ClientConfigurationTitleComponent } from './components/client-configuration-title/client-configuration-title.component';
import { CaptureAdminMyInboundFaxesComponent } from './pages/capture-admin-my-inbound-faxes/capture-admin-my-inbound-faxes.component';
import { CaptureAdminMyConsultNotesComponent } from './pages/capture-admin-my-consult-notes/capture-admin-my-consult-notes.component';
import { CaptureAdminMyClientChoresComponent } from './pages/capture-admin-my-client-chores/capture-admin-my-client-chores.component';
import { PatientStatusAndDeactivateButtonComponent } from './components/patient-status-and-deactivate-button/patient-status-and-deactivate-button.component';
import { CaptureAdminOfficesComponent } from './pages/capture-admin-offices/capture-admin-offices.component';
import { CaptureAdminOfficeComponent } from './pages/capture-admin-office/capture-admin-office.component';
import { CaptureAdminOfficeNewComponent } from './pages/capture-admin-office-new/capture-admin-office-new.component';
import { OfficeNavComponent } from './components/office-nav/office-nav.component';
import { OfficeHeaderComponent } from './components/office-header/office-header.component';
import { OfficeHeaderEditComponent } from './components/office-header-edit/office-header-edit.component';
import { OfficeDetailComponent } from './components/office-detail/office-detail.component';
import { OfficeCommunicationsComponent } from './components/office-communications/office-communications.component';
import { OfficeProviderListComponent } from './components/office-provider-list/office-provider-list.component';
import { OfficeProviderNewFormComponent } from './components/office-provider-new-form/office-provider-new-form.component';
import { OfficeProviderNewRowComponent } from './components/office-provider-new-row/office-provider-new-row.component';
import { OfficeOutboundFaxesComponent } from './components/office-outbound-faxes/office-outbound-faxes.component';
import { UserStatsComponent } from './components/user-stats/user-stats.component';
import { ProviderOfficeComponent } from './components/provider-office/provider-office.component';
import { InboundCallLogComponent } from './components/inbound-call-log/inbound-call-log.component';
import { InboundFaxCommunicationMethodComponent } from './components/inbound-fax-communication-method/inbound-fax-communication-method.component';
import { ClientStatsComponent } from './components/client-stats/client-stats.component';
import { InboundFaxMultiplePatientsWarningComponent } from './components/inbound-fax-multiple-patients-warning/inbound-fax-multiple-patients-warning.component';
import { InboundFaxPossiblePatientsComponent } from './components/inbound-fax-possible-patients/inbound-fax-possible-patients.component';
import { CaptureAdminPatientInteractionLocationsComponent } from './pages/capture-admin-patient-interaction-locations/capture-admin-patient-interaction-locations.component';
import { ClientChoreReviewComponent } from './components/client-chore-review/client-chore-review.component';
import { ClientChoreStatusComponent } from './components/client-chore-status/client-chore-status.component';
import { ClientChoreLifecycleComponent } from './components/client-chore-lifecycle/client-chore-lifecycle.component';
import { ClientChoreListComponent } from './components/client-chore-list/client-chore-list.component';
import { ClientChoreFiltersComponent } from './components/client-chore-filters/client-chore-filters.component';
import { NewOfficeModalComponent } from './components/new-office-modal/new-office-modal.component';
import { NewProviderModalComponent } from './components/new-provider-modal/new-provider-modal.component';
import { ProviderOfficeFaxStatusComponent } from './components/provider-office-fax-status/provider-office-fax-status.component';
import { OfficeFaxStatusComponent } from './components/office-fax-status/office-fax-status.component';
import { ProviderStatusEditComponent } from './components/provider-status-edit/provider-status-edit.component';
import { CaptureAdminOfficeAuditComponent } from './pages/capture-admin-office-audit/capture-admin-office-audit.component';
import { AuditOfficeCreateRowComponent } from './components/audit-office-create-row/audit-office-create-row.component';
import { AuditOfficeUpdateRowComponent } from './components/audit-office-update-row/audit-office-update-row.component';
import { AuditNoteCreateRowComponent } from './components/audit-note-create-row/audit-note-create-row.component';
import { AuditProviderOfficeCreateRowComponent } from './components/audit-provider-office-create-row/audit-provider-office-create-row.component';
import { AuditProviderOfficeUpdateRowComponent } from './components/audit-provider-office-update-row/audit-provider-office-update-row.component';
import { CaptureAdminProviderAuditComponent } from './pages/capture-admin-provider-audit/capture-admin-provider-audit.component';
import { AuditProviderCreateRowComponent } from './components/audit-provider-create-row/audit-provider-create-row.component';
import { AuditProviderUpdateRowComponent } from './components/audit-provider-update-row/audit-provider-update-row.component';
import { AuditOfficeActionMetaComponent } from './components/audit-office-action-meta/audit-office-action-meta.component';
import { CaptureAdminPatientInteractionLocationReportsComponent } from './pages/capture-admin-patient-interaction-location-reports/capture-admin-patient-interaction-location-reports.component';
import { CaptureAdminPatientCommunicationsComponent } from './pages/capture-admin-patient-communications/capture-admin-patient-communications.component';
import { CaptureAdminPatientCapturesComponent } from './pages/capture-admin-patient-captures/capture-admin-patient-captures.component';
import { CaptureAdminPatientReferralsComponent } from './pages/capture-admin-patient-referrals/capture-admin-patient-referrals.component';
import { CaptureAdminPatientEncountersComponent } from './pages/capture-admin-patient-encounters/capture-admin-patient-encounters.component';
import { CaptureAdminPatientPrescriptionsComponent } from './pages/capture-admin-patient-prescriptions/capture-admin-patient-prescriptions.component';
import { PatientNavComponent } from './components/patient-nav/patient-nav.component';
import { PatientHeaderComponent } from './components/patient-header/patient-header.component';
import { PatientDemographicsComponent } from './components/patient-demographics/patient-demographics.component';
import { PatientGroupedPatientsComponent } from './components/patient-grouped-patients/patient-grouped-patients.component';
import { PatientCommunicationsComponent } from './components/patient-communications/patient-communications.component';
import { PatientInboundCallsComponent } from './components/patient-inbound-calls/patient-inbound-calls.component';
import { PatientDeactivateButtonComponent } from './components/patient-deactivate-button/patient-deactivate-button.component';
import { PatientCapturesSearchSettingsComponent } from './components/patient-captures-search-settings/patient-captures-search-settings.component';
import { PatientCapturesSearchSummaryComponent } from './components/patient-captures-search-summary/patient-captures-search-summary.component';
import { CaptureAdminProviderCommunicationsComponent } from './pages/capture-admin-provider-communications/capture-admin-provider-communications.component';
import { CaptureAdminProviderPrescriptionsComponent } from './pages/capture-admin-provider-prescriptions/capture-admin-provider-prescriptions.component';
import { CaptureAdminProviderEncountersComponent } from './pages/capture-admin-provider-encounters/capture-admin-provider-encounters.component';
import { CaptureAdminProviderCapturesComponent } from './pages/capture-admin-provider-captures/capture-admin-provider-captures.component';
import { ProviderCapturesSearchSettingsComponent } from './components/provider-captures-search-settings/provider-captures-search-settings.component';
import { ProviderCapturesSearchSummaryComponent } from './components/provider-captures-search-summary/provider-captures-search-summary.component';
import { CaptureAdminUserAuditComponent } from './pages/capture-admin-user-audit/capture-admin-user-audit.component';
import { CaptureAdminInternalConsultantCapturesComponent } from './pages/capture-admin-internal-consultant-captures/capture-admin-internal-consultant-captures.component';
import { InternalConsultantCaptureSettingsComponent } from './components/internal-consultant-capture-settings/internal-consultant-capture-settings.component';

@NgModule({
  imports: [SharedModule, CapturesModule, ConsultNotesModule, ClientTasksModule, IccModule, InfiniteScrollModule],
  declarations: [
    InboundFaxHeaderComponent,
    InboundFaxInfoComponent,
    InboundFaxContentComponent,
    InboundFaxSelectPatientComponent,
    PatientSearchFormComponent,
    PatientListComponent,
    EncounterListComponent,
    SelectPrescriberComponent,
    PrescriberListComponent,
    InboundFaxSelectOutcomeComponent,
    CaptureAdminComponent,
    CaptureAdminInboundFaxesComponent,
    CaptureAdminInboundFaxComponent,
    CaptureAdminFaxUploadComponent,
    CaptureAdminCaptureReportsComponent,
    CaptureAdminCaptureBillingStateReportsComponent,
    CaptureAdminCaptureInvoiceLineItemReportsComponent,
    CaptureAdminSpecialtyStoreClaimPatientMatchReportsComponent,
    CaptureAdminUserTimingReportsComponent,
    CaptureAdminMissingNdcReportsComponent,
    CaptureAdminFaxablePatientPrescriberReportsComponent,
    CaptureAdminClientsComponent,
    CaptureAdminClientConsultNotesComponent,
    CaptureAdminClientPrescriberTasksComponent,
    ClientPrescriberTaskStatusComponent,
    ClientPrescriberTaskListComponent,
    ClientPrescriberTaskCapturesComponent,
    ClientPrescriberTaskVisibilityComponent,
    InboundFaxCapturesComponent,
    InboundFaxProcessFormComponent,
    InboundFaxViewProcssedComponent,
    CaptureAdminFileCheckResultsComponent,
    CaptureAdminFileCheckComponent,
    CaptureAdminClientFilesComponent,
    CaptureAdminPartnerFilesComponent,
    FileCheckNavComponent,
    FileCheckStatusComponent,
    CaptureAdminUsersComponent,
    CaptureAdminUserComponent,
    CaptureAdminUserNewComponent,
    UserActionsComponent,
    CaptureAdminPartnerPortalClaimReportComponent,
    CaptureAdminUserEditComponent,
    UserFiltersComponent,
    UserFormComponent,
    UserHeaderComponent,
    CaptureAdminImportReferralPrescriberSpecialtyMappingComponent,
    CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent,
    CaptureAdminImportOutboundFaxesComponent,
    CaptureAdminImportOutboundFaxesHistoryComponent,
    CaptureAdminImportClaimsComponent,
    CaptureAdminImportClaimsHistoryComponent,
    CaptureAdminClientEditComponent,
    CaptureAdminClientEditConfigurationComponent,
    CaptureAdminClientNewComponent,
    CaptureAdminClientConsultNoteTasksComponent,
    ClientConsultNoteTaskListComponent,
    OutboundFaxesComponent,
    CaptureAdminImportCaptureInvoiceLineItemsComponent,
    CaptureAdminImportCaptureInvoiceLineItemsHistoryComponent,
    CaptureAdminImportPatientInteractionLocationsComponent,
    CaptureAdminImportPatientInteractionLocationsHistoryComponent,
    ClientListComponent,
    CaptureAdminClientComponent,
    CaptureAdminAuditComponent,
    CaptureAdminClientOptionsAuditComponent,
    CaptureAdminClientAuditComponent,
    ClientActionsComponent,
    ClientFormComponent,
    CaptureAdminCaptureQueueComponent,
    CaptureQueueSettingsComponent,
    CaptureQueueResultsComponent,
    SendDirectTestMessageComponent,
    CaptureAdminClientDirectConsultNotesComponent,
    CaptureAdminClientReferralTasksComponent,
    ClientReferralTaskListComponent,
    CaptureAdminInboundCallsComponent,
    CaptureAdminInboundCallComponent,
    CaptureAdminInboundCallNewComponent,
    OutboundFaxSearchFormComponent,
    OutboundFaxSelectorComponent,
    CaptureAdminPrescriberIncorrectInformationRequestsComponent,
    CaptureAdminNameMappingsComponent,
    CaptureAdminClientChoresComponent,
    CaptureAdminClientChoreCreateComponent,
    CaptureAdminImportCuratedSpecialtiesComponent,
    CaptureAdminImportCuratedSpecialtiesHistoryComponent,
    CaptureAdminCaptureSearchComponent,
    CaptureSearchSettingsComponent,
    CaptureSearchResultsComponent,
    CaptureAdminPatientsComponent,
    CaptureAdminPatientComponent,
    CaptureAdminProvidersComponent,
    CaptureAdminProviderComponent,
    CaptureAdminProviderNewComponent,
    CaptureAdminProvidersMechanicalTurkQueueComponent,
    CaptureAdminProviderOfficeStatusReviewQueueComponent,
    CaptureAdminOfficeStatusReviewQueueComponent,
    ProviderOfficeListComponent,
    ProviderOfficeListBasicComponent,
    ProviderOfficeNewFormComponent,
    ProviderOfficeNewRowComponent,
    ProviderNavComponent,
    ProviderHeaderComponent,
    ProviderHeaderEditComponent,
    ProviderInformationCollectionDetailsComponent,
    ProviderDetailComponent,
    ProviderCallLogsComponent,
    ProviderCallLogFormComponent,
    ProviderCommunicationsComponent,
    ProviderOutboundFaxesComponent,
    PrescriptionsComponent,
    PatientFiltersComponent,
    ClientConfigurationBasicValueComponent,
    ClientConfigurationDatedValueComponent,
    ClientConfigurationSuboptionComponent,
    ClientConfigurationSuboptionsComponent,
    ClientConfigurationTitleComponent,
    CaptureAdminMyInboundFaxesComponent,
    CaptureAdminMyConsultNotesComponent,
    CaptureAdminMyClientChoresComponent,
    PatientStatusAndDeactivateButtonComponent,
    CaptureAdminOfficesComponent,
    CaptureAdminOfficeComponent,
    CaptureAdminOfficeNewComponent,
    OfficeNavComponent,
    OfficeHeaderComponent,
    OfficeHeaderEditComponent,
    OfficeDetailComponent,
    OfficeCommunicationsComponent,
    OfficeProviderListComponent,
    OfficeProviderNewFormComponent,
    OfficeProviderNewRowComponent,
    OfficeOutboundFaxesComponent,
    UserStatsComponent,
    ProviderOfficeComponent,
    InboundCallLogComponent,
    InboundFaxCommunicationMethodComponent,
    ClientStatsComponent,
    InboundFaxMultiplePatientsWarningComponent,
    InboundFaxPossiblePatientsComponent,
    CaptureAdminPatientInteractionLocationsComponent,
    ClientChoreReviewComponent,
    ClientChoreStatusComponent,
    ClientChoreLifecycleComponent,
    ClientChoreListComponent,
    ClientChoreFiltersComponent,
    NewOfficeModalComponent,
    NewProviderModalComponent,
    ProviderOfficeFaxStatusComponent,
    OfficeFaxStatusComponent,
    ProviderStatusEditComponent,
    CaptureAdminOfficeAuditComponent,
    AuditOfficeCreateRowComponent,
    AuditOfficeUpdateRowComponent,
    AuditNoteCreateRowComponent,
    AuditProviderOfficeCreateRowComponent,
    AuditProviderOfficeUpdateRowComponent,
    CaptureAdminProviderAuditComponent,
    AuditProviderCreateRowComponent,
    AuditProviderUpdateRowComponent,
    AuditOfficeActionMetaComponent,
    CaptureAdminPatientInteractionLocationReportsComponent,
    CaptureAdminPatientCommunicationsComponent,
    CaptureAdminPatientCapturesComponent,
    CaptureAdminPatientReferralsComponent,
    CaptureAdminPatientEncountersComponent,
    CaptureAdminPatientPrescriptionsComponent,
    PatientNavComponent,
    PatientHeaderComponent,
    PatientDemographicsComponent,
    PatientGroupedPatientsComponent,
    PatientCommunicationsComponent,
    PatientInboundCallsComponent,
    PatientDeactivateButtonComponent,
    PatientCapturesSearchSettingsComponent,
    PatientCapturesSearchSummaryComponent,
    CaptureAdminProviderCommunicationsComponent,
    CaptureAdminProviderPrescriptionsComponent,
    CaptureAdminProviderEncountersComponent,
    CaptureAdminProviderCapturesComponent,
    ProviderCapturesSearchSettingsComponent,
    ProviderCapturesSearchSummaryComponent,
    CaptureAdminUserAuditComponent,
    CaptureAdminInternalConsultantCapturesComponent,
    InternalConsultantCaptureSettingsComponent,
  ],
  providers: [],
})
export class CaptureAdminModule { }
